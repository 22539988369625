<template>
  <div id="home" class="main-body">
    <div id="smart-logistic" class="smart-logistic p-2 p-md-5 mt-5">
      <div class="text-center">
        <h1 class="title fs-1 text-center">
          Request a
          <span class="text-primary">Quote</span>
        </h1>
        <p class="mt-3">Learn our quotation process below.</p>
      </div>

      <b-row class="text-center mt-5">
        <b-col cols="12" md="3">
          <h2 class="text-primary">01</h2>
          <p class="subtitle fs-1">Choose delivery type</p>
        </b-col>
        <b-col cols="12" md="3">
          <h2 class="text-primary">02</h2>
          <p class="subtitle fs-1">Enter origin and destination</p>
        </b-col>
        <b-col cols="12" md="3">
          <h2 class="text-primary">03</h2>
          <p class="subtitle fs-1">Get delivery prices</p>
        </b-col>
        <b-col cols="12" md="3">
          <h2 class="text-primary">04</h2>
          <p class="subtitle fs-1">Proceed with delivery</p>
        </b-col>
      </b-row>

      <b-card class="mt-5 label" style="background-color: #f8f9fa">
        <p class="subtitle text-left">Choose your delivery type below</p>
        <b-tabs content-class="bg-white" class="p-md-1 p-2">
          <b-tab title="Local" active>
            <div class="row">
              <div class="col">
                <div class="p-1 p-md-3">
                  <b-form-group label="Shipment Type" label-for="shipment-type">
                    <b-form-select
                      id="shipment-type"
                      :options="shipmentTypes"
                      v-model="shipmentType"
                      placeholder="Select shipment type"
                      class="custom-input"
                    ></b-form-select>
                  </b-form-group>
                  <b-row v-if="shipmentType === 'distributor'">
                    <b-col cols="12" md="6">
                      <b-form-group label="From" label-for="from-state">
                        <b-form-select
                          id="from-state"
                          :options="states"
                          v-model="fromState"
                          placeholder="Select state"
                          class="custom-input"
                        ></b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-group label="To" label-for="to-state">
                        <b-form-select
                          id="to-state"
                          :options="states"
                          v-model="toState"
                          placeholder="Select state"
                          class="custom-input"
                        ></b-form-select>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-button
                    variant="primary"
                    class="mt-4"
                    style="border-radius: 10px"
                    @click.prevent="onSubmit"
                  >
                    Get a Quote
                  </b-button>
                </div>
              </div>
              <div
                style="
                  border: 1px rgb(199, 199, 199) solid;
                  height: 250px;
                  align-self: center;
                "
              ></div>
              <div class="col">
                <div class="p-1 p-md-3 row align-items-center">
                  <div class="col-sm-12 col-md-12 col-lg-6">
                    <div class="row my-1">
                      <div class="col">
                        <span class="d-block">ESTIMATED PRICE:</span>
                        <span class="d-block">
                          <span
                            v-if="!isLoading"
                            class="h1 text-primary font-large-2"
                            >RM {{ unitPrice ? unitPrice : "0.00" }}</span
                          >
                          <b-spinner
                            v-else
                            variant="primary"
                            class="text-center"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="col-sm-12 col-md-12 col-lg-6">
                    <b-button
                      variant="gradient-primary"
                      class="w-100 box-shadow-1"
                      @click="onOrder"
                      >Proceed to Order</b-button
                    >
                  </div> -->
                </div>
                <div v-if="unitPrice > 0" class="pl-3">
                  <span class="h1 text-primary font-large-1">
                    Lowest price guaranteed.
                  </span>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab title="International">
            <div class="p-1 p-md-3">
              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group label="Name" label-for="name">
                    <b-form-input
                      id="name"
                      v-model="name"
                      placeholder="Enter name"
                      class="custom-input"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group label="Email" label-for="email">
                    <b-form-input
                      id="email"
                      type="email"
                      v-model="email"
                      placeholder="Enter email"
                      class="custom-input"
                      required
                      pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group label="Phone Number" label-for="phone">
                    <b-form-input
                      id="phone"
                      v-model="phone"
                      placeholder="Enter phone number"
                      class="custom-input"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group label="Company" label-for="company">
                    <b-form-input
                      id="company"
                      v-model="company"
                      placeholder="Enter company"
                      class="custom-input"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group label="From" label-for="from-state">
                    <b-form-select
                      id="from-state"
                      :options="states"
                      v-model="fromState"
                      placeholder="Select state"
                      class="custom-input"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group label="To" label-for="to-state">
                    <b-form-select
                      id="to-state"
                      :options="states"
                      v-model="toState"
                      placeholder="Select state"
                      class="custom-input"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group label="City (From)" label-for="city-from">
                    <b-form-input
                      id="city-from"
                      v-model="cityFrom"
                      placeholder="Enter city from"
                      class="custom-input"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group
                    label="Postcode (From)"
                    label-for="postcode-from"
                  >
                    <b-form-input
                      id="postcode-from"
                      v-model="postcodeFrom"
                      placeholder="Enter postcode from"
                      class="custom-input"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group label="City (To)" label-for="city-to">
                    <b-form-input
                      id="city-to"
                      v-model="cityTo"
                      placeholder="Enter city to"
                      class="custom-input"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group label="Postcode (To)" label-for="postcode-to">
                    <b-form-input
                      id="postcode-to"
                      v-model="postcodeTo"
                      placeholder="Enter postcode to"
                      class="custom-input"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group
                    label="Product Category"
                    label-for="product-category"
                  >
                    <b-form-input
                      id="product-category"
                      v-model="productCategory"
                      placeholder="Enter product category"
                      class="custom-input"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group label="Packaging" label-for="packaging">
                    <b-form-input
                      id="packaging"
                      v-model="packaging"
                      placeholder="Enter packaging"
                      class="custom-input"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group label="Remarks" label-for="remarks">
                    <b-form-input
                      id="remarks"
                      v-model="remarks"
                      placeholder="Enter remarks"
                      class="custom-input"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group label="SKU" label-for="sku">
                    <b-form-input
                      id="sku"
                      v-model="sku"
                      placeholder="Enter SKU"
                      class="custom-input"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group label="Temperature" label-for="temperature">
                    <b-form-input
                      id="temperature"
                      v-model="temperature"
                      placeholder="Enter temperature"
                      class="custom-input"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group label="Do you need pallet?" label-for="pallet">
                    <b-form-select
                      id="pallet"
                      :options="palletOptions"
                      v-model="pallet"
                      placeholder="Select pallet option"
                      class="custom-input"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group label="Dimension" label-for="dimension">
                    <b-form-input
                      id="dimension"
                      v-model="dimension"
                      placeholder="0 x 0 x 0"
                      class="custom-input"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group label="Quantity" label-for="quantity">
                    <b-form-input
                      id="quantity"
                      v-model="quantity"
                      placeholder="Enter quantity"
                      class="custom-input"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group label="Weight" label-for="weight">
                    <b-form-input
                      id="weight"
                      v-model="weight"
                      placeholder="Enter weight"
                      class="custom-input"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-button
                variant="primary"
                class="mt-4"
                style="border-radius: 10px"
                @click.prevent="sendEmail"
              >
                Get a Quote
              </b-button>
            </div>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      fromState: "",
      toState: "",
      shipmentType: "",
      name: "",
      email: "",
      phone: "",
      company: "",
      errorMessage: "",
      from: "",
      to: "",
      unitPrice: "",
      cityFrom: "",
      postcodeFrom: "",
      cityTo: "",
      postcodeTo: "",
      productCategory: "",
      packaging: "",
      remarks: "",
      sku: "",
      temperature: "",
      pallet: "",
      dimension: "",
      quantity: "",
      weight: "",
      palletOptions: [
        { value: "yes", text: "Yes" },
        { value: "no", text: "No" },
      ],
      states: [
        { value: null, text: "Please select a state" },
        { value: "johor", text: "Johor" },
        { value: "kedah", text: "Kedah" },
        { value: "melaka", text: "Melaka" },
        { value: "negeri-sembilan", text: "Negeri Sembilan" },
        { value: "pahang", text: "Pahang" },
        { value: "penang", text: "Penang" },
        { value: "perak", text: "Perak" },
        { value: "perlis", text: "Perlis" },
        { value: "sabah", text: "Sabah" },
        { value: "sarawak", text: "Sarawak" },
        { value: "putrajaya", text: "Putrajaya" },
        { value: "greater klang valley", text: "Greater Klang Valley" },
        { value: "klang valley", text: "Klang Valley" },
      ],
      shipmentTypes: [
        { value: null, text: "Please select a shipment type" },
        { value: "outlet", text: "Outlet(Walk-in)" },
        { value: "distributor", text: "Distributor" },
      ],
    };
  },
  methods: {
    sendEmail() {
      const subject = `New Form Submission ${this.name} - International Shipment Quotation`;
      const body = encodeURIComponent(this.getEmailBody());
      const mailtoLink = `mailto:customerservice@glogc.com?subject=${subject}&body=${body}`;
      window.location.href = mailtoLink;
    },
    getEmailBody() {
      var emailHTML = `From: ${this.name}\nCompany: ${this.company}\nEmail: ${this.email}\nPhone Number: ${this.phone}\n\nSender Address:\nCountry: [Sender Country]\nCity: ${this.cityFrom}\nPostcode: ${this.postcodeFrom}\n\nReceiver Address:\nCountry: [Receiver Country]\nCity: ${this.cityTo}\nPostcode: ${this.postcodeTo}\n\nPackage Details:\nProduct Category: ${this.productCategory}\nPackaging: ${this.packaging}\nSKU: ${this.sku}\nTemperature Requirement: ${this.temperature}\nPallet: ${this.pallet}\nDimension: ${this.dimension}\nQuantity: ${this.quantity}\nWeight: ${this.weight}\nRemarks: ${this.remarks}\n\nMessage:\nThis is an automated submission for the form completed by ${this.name}. Please review the details above and contact the customer as necessary.`;
      emailHTML = emailHTML.replace(/\t/g, ""); // Remove tabs

      return emailHTML;
    },
    async onSubmit() {
      this.isLoading = true;
      if (this.shipmentType === "outlet") {
        this.unitPrice = 35.0;
      } else if (this.shipmentType === "distributor") {
        if (
          this.fromState === "klang valley" &&
          this.toState === "greater klang valley"
        ) {
          this.unitPrice = 25.0;
        } else if (
          this.fromState === "klang valley" &&
          this.toState === "klang valley"
        ) {
          this.unitPrice = 20.0;
        } else if (
          this.toState === "greater klang valley" ||
          this.fromState === "greater klang valley"
        ) {
          this.unitPrice = 30.0;
        } else if (this.toState === this.fromState) {
          this.unitPrice = 20.0;
        } else {
          this.unitPrice = 30.0;
        }
      }
      this.errorMessage = "";
      this.isLoading = false;
    },
  },
};
</script>
<style lang="scss">
.label {
  color: #0c1844;
  font-size: 2rem;
  font-weight: 700;
}
.custom-input {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  font-size: 1.2rem;
  padding: 10px;
  height: 3rem;
}
//HEREEEEEEEEEEEEE
</style>
